<template>
  <v-app>
    <resource-menu />

    <kdw-Statusbar-card ref="kdwsnackbar" />

    <common-view />

    <common-footer />

    <kdw-Cookiebar-card v-model="cookieUpdate" />
  </v-app>
</template>

<script>
export default {
  name: "ResourceLayout",
  components: {
    ResourceMenu: () => import("@/layouts/resource/Menu"),
    CommonFooter: () => import("@/layouts/Footer"),
    CommonView: () => import("@/layouts/View"),
  },

  data: () => {
    return { cookieUpdate: false };
  },

  mounted() {
    this.$root.kdwsnackbar = this.$refs.kdwsnackbar;
  },
};
</script>
